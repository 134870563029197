import React, { useEffect, useState } from "react";
import { TicketsDesktop } from "./desktop/tickets";
import { TicketsMobile } from "./mobile/tickets";
import { useHistory } from "react-router";
import LoadingIndicator from "../reusable/loadingIndicator";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { CLEAR_STORE } from "../actions/types";
import { version } from "../../package.json";
import { TicketLogin } from "../reducers/ticket";
import { getTicket } from "../actions/ticket";
import { RootState } from "../reducers";

interface TicketsProps {
  match: any;
}

const Tickets = (props: TicketsProps) => {
  const { isLoading } = useSelector((state: RootState) => state.loader);

  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();

  const [ticket, setTicket] = useState<TicketLogin>({
    licensePlate: props.match.params.licensePlate,
    number: props.match.params.referenceNumber,
  });

  useEffect(() => {
    dispatch({
      type: CLEAR_STORE,
    });
  });

  const enterReferenceAndLicensePlate = async () => {
    dispatch(getTicket(ticket, setError, history));
  };

  const shouldDisable = () => {
    if (ticket.licensePlate && ticket.number) {
      return false;
    }
    return true;
  };

  const renderContent = () => {
    if (isMobile) {
      return (
        <TicketsMobile
          enterReferenceAndLicensePlate={enterReferenceAndLicensePlate}
          setTicket={setTicket}
          ticket={ticket}
          error={error}
          setError={setError}
          shouldDisable={shouldDisable}
          version={version}
        />
      );
    }
    return (
      <>
        <TicketsDesktop
          enterReferenceAndLicensePlate={enterReferenceAndLicensePlate}
          setTicket={setTicket}
          ticket={ticket}
          error={error}
          setError={setError}
          shouldDisable={shouldDisable}
          version={version}
        />
      </>
    );
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return renderContent();
};

export default Tickets;
