import React from "react";
import "./App.css";
import { Redirect, Route, Switch } from "react-router-dom";
import { persistor, store } from "./store";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import tiketDetails from "./components/ticketsDetails";
import orderMobile from "./components/orderMobile";
import pzTextPageMobile from "./components/pzTextPageMobile";
import evidencePhotos from "./components/evidencePhotos";
import objectionMobile from "./components/objectionMobile";
import tickets from "./components/tickets";
import bankTransferMobile from "./components/bankTransferMobile";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import useGaTracker from "./useGaTracker";
import EmailVerification from "./components/external/emailVerification";
import ResetPassword from "./components/external/resetPassword";
import SocarPhoto from "./components/socarPhoto";

function App() {
  useGaTracker();

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Switch>
          <Route exact path="/ticket-details" component={tiketDetails} />
          <Route
            exact
            path="/ticket-details/paymentSuccessfull"
            component={tiketDetails}
          />
          <Route
            exact
            path="/ticket-details/paymentError"
            component={tiketDetails}
          />
          <Route
            exact
            path="/emailVerification/:id"
            component={EmailVerification}
          />
          <Route path="/resetPassword" component={ResetPassword} />

          <Route
            exact
            path={[
              "/tickets/:referenceNumber",
              "/tickets",
              "/tickets/:referenceNumber/:licensePlate",
            ]}
            component={tickets}
          />
          <Route exact path="/order" component={orderMobile} />
          <Route exact path="/socarPhoto" component={SocarPhoto} />
          <Route exact path="/pz-text" component={pzTextPageMobile} />
          <Route exact path="/evidence" component={evidencePhotos} />
          <Route exact path="/bank-transfer" component={bankTransferMobile} />
          <Route exact path="/complain" component={objectionMobile} />

          <Redirect to="/tickets" />
        </Switch>
      </PersistGate>
    </Provider>
  );
}

export default App;
