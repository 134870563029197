import React from "react";
import { useSelector } from "react-redux";
import Style from "./bankTransferMobile.module.scss";
import { useTranslation } from "react-i18next";
import {
  businessName,
  country,
  iban,
  postalCode,
  streetName,
  streetNumber,
  townName,
  bic,
  bank,
  ibanText,
  ibanSocar,
  ibanSocarText,
} from "../reusable/functions";
import QRCode from "react-qr-code";
import QRLogo from "../common/assets/qrLogo.png";
import { RootState } from "../reducers";
import GenericMobileCard from "./general/genericMobileCard";
import { TicketStatus } from "../reducers/ticket";

const BankTransferMobile = () => {
  const ticket = useSelector((state: RootState) => state.ticket.ticket);
  const { t } = useTranslation();

  const totalAmount =
    ticket.gasStationZone != null
        ? (ticket.totalGasStationAmount-ticket?.paidAmount).toFixed(2)
      : ticket.status === TicketStatus.PAID
      ? ticket.paidAmount
        ? ticket.paidAmount.toFixed(2)
        : ticket?.amount.toFixed(2)
      : ticket?.reminderDeadline
      ? (
          ticket?.amount +
          ticket?.reminderFee +
          ticket?.additionalCost -
          ticket?.paidAmount
        ).toFixed(2)
      : (ticket?.amount - ticket?.paidAmount).toFixed(2);

  const ibanInQR = ticket.gasStationZone != null ? ibanSocar : iban;

  const qrValue =
    "SPC\n" +
    "0200\n" +
    "1\n" +
    ibanInQR +
    "\n" +
    "S\n" +
    businessName +
    "\n" +
    streetName +
    "\n" +
    streetNumber +
    "\n" +
    postalCode +
    "\n" +
    townName +
    "\n" +
    country +
    "\n" +
    "\n" +
    "\n" +
    "\n" +
    "\n" +
    "\n" +
    "\n" +
    "\n" +
    totalAmount +
    "\n" +
    "CHF\n" +
    "\n" +
    "\n" +
    "\n" +
    "\n" +
    "\n" +
    "\n" +
    "\n" +
    "NON\n" +
    "\n" +
    ticket.number +
    "\n" +
    "EPD\n";

  return (
    <GenericMobileCard title={t("TicketDetails.BankTransfer").toString()}>
      <div className={Style.qrContainer}>
        <div className={Style.qrContainer__title}>
          {t("TicketDetails.QR title").toString()}
        </div>
        <div className={Style.qrContainer__box}>
          <img src={QRLogo} alt="qrLogo" className={Style.qrContainer__icon} />
          <QRCode value={qrValue} size={150} />
        </div>
      </div>
      <div className={Style.detailsContainer}>
        <div className={Style.detailsContainer__heading}>
          {t("TicketDetails.Account details").toString()}
        </div>
        <div style={{ marginTop: "10px" }}>
          <div className={Style.detailsContainer__paragraphMobile}>
            <span>{t("TicketDetails.Account owner").toString()}</span>
            <div className={Style.detailsContainer__horizontalLine}></div>
            <span className={Style.detailsContainer__paragraphBold}>
              {businessName}
            </span>
          </div>
          <div className={Style.detailsContainer__paragraphMobile}>
            <span>IBAN</span>
            <div className={Style.detailsContainer__horizontalLine}></div>
            <span className={Style.detailsContainer__paragraphBold}>
              {ticket.gasStationZone != null ? ibanSocarText : ibanText}
            </span>
          </div>
          <div className={Style.detailsContainer__paragraphMobile}>
            <span>BIC</span>
            <div className={Style.detailsContainer__horizontalLine}></div>
            <span className={Style.detailsContainer__paragraphBold}>{bic}</span>
          </div>
          <div className={Style.detailsContainer__paragraphMobile}>
            <span>{t("TicketDetails.Financial institution").toString()}</span>
            <div className={Style.detailsContainer__horizontalLine}></div>
            <span className={Style.detailsContainer__paragraphBold}>
              {bank}
            </span>
          </div>
          <div className={Style.detailsContainer__paragraphMobile}>
            <span>{t("TicketDetails.Amount").toString()}</span>
            <div className={Style.detailsContainer__horizontalLine}></div>
            <span className={Style.detailsContainer__paragraphBold}>
              {totalAmount}
            </span>
          </div>
          <div className={Style.detailsContainer__paragraphMobile}>
            <span style={{ color: "#CC0605" }}>
              {t("TicketDetails.Additional information mobile").toString()}
            </span>
            <div className={Style.detailsContainer__horizontalLine}></div>
            <span
              className={Style.detailsContainer__paragraphBold}
              style={{ color: "#CC0605" }}
            >
              {ticket.number}
            </span>
          </div>
        </div>
      </div>
    </GenericMobileCard>
  );
};

export default BankTransferMobile;
