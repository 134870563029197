import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";
import Navbar from "../reusable/navbar";
import PaymentButton from "../common/button/paymentButton";
import EnvelopeBlue from "../common/assets/envelopeBlue.svg";
import CreditCardWhite from "../common/assets/credit-cardWhite.svg";
import ShippingFastBlue from "../common/assets/shipping-fastBlue.svg";
import DownloadBlue from "../common/assets/downloadBlue.svg";
import MoneyIcon from "../common/assets/money-icon.svg";
import Style from "./ticketsDetails.module.scss";
import { useTranslation } from "react-i18next";
import ParkingZoneTextModal from "./parkingZoneTextModal";
import EvidencePhotosModal from "./evidencePhotosModal";
import ObjectionModal from "./objectionModal";
import InfoModal from "./infoModal";
import BankTransferModal from "./bankTransferModal";
import OrderBillModal from "./orderBillModal";
import { RootState } from "../reducers";
import BeppoLightBox from "../reusable/BeppoLightBox";
import { downloadSlip, payment } from "../actions/ticket";
import GenericMobileCard from "./general/genericMobileCard";
import TicketDetailsGasStationDesktop from "./TicketDetailsGasStationDesktop";
import TicketDetailsGasStationMobile from "./TicketDetailsGasStationMobile";
import LoadingIndicator from "../reusable/loadingIndicator";
import { TicketStatus } from "../reducers/ticket";

const FINAL_STATUSES: string[] = ["PAID", "STOPPED"];

const TiketDetails = () => {
  const ticket = useSelector((state: RootState) => state.ticket.ticket);
  const appeal = useSelector((state: RootState) => state.ticket.appeal);
  const { isLoading } = useSelector((state: RootState) => state.loader);

  const history = useHistory();
  const dispatch = useDispatch();
  const [, setError] = useState("");
  const [images] = useState(ticket.imageUrls);
  const { t, i18n } = useTranslation();
  const [openPZTextModal, setOpenPZTextModal] = useState(false);
  const [openEvidencePhotosModal, setOpenEvidencePhotosModal] = useState(false);
  const [openObjectionModal, setOpenObjectionModal] = useState(false);
  const [openOrderBillModal, setOpenOrderBillModal] = useState(false);
  const [, setOpenDownloadSlipInfoModal] = useState(false);
  const [openOrderSlipInfoModal, setOpenOrderSlipInfoModal] = useState(false);
  const [openPayment, setOpenPayment] = useState(false);
  const [openBankTransferModal, setOpenBankTransferModal] = useState(false);
  const [lightbox, showLightbox] = useState(false);
  const [transaction, setTransaction] = useState("");
  const [
    datatransPaymentSuccessffullModalOpen,
    setDatatransPaymentSuccessffullModalOpen,
  ] = useState(false);
  const [datatransPaymentErrorModalOpen, setDatatransPaymentErrorModalOpen] =
    useState(false);
  const selectedLanguage = localStorage.getItem("beppoSelectedLanguage");
  const lang = selectedLanguage ? selectedLanguage : i18n.language;

  const datatransProductionMode =
    process.env.REACT_APP_DATATRANS_PRODUCTION_MODE;

  var isProduction = datatransProductionMode === "true";
  const isGasStationTicket = ticket?.gasStationZone != null;

  const onLoaded = () => console.log("Loaded");
  const onOpened = () => console.log("Opened");
  const onCancelled = () => {
    console.log("Error:", "Cancel");
    showLightbox(false);
  };
  const onError = () => {
    console.log("Error with datatrans");
    showLightbox(false);
  };

  const paymentPeriodDate = () => {
    let date = new Date(ticket.date);
    if(ticket.status == "PENDING"){
      date = new Date();
    }
    if(ticket.issueDate!=null) {
      date = new Date(ticket.issueDate);
    }
    date.setDate(date.getDate() + ticket?.paymentPeriod);
    return date;
  };

  const totalAmount =
  ticket.status === TicketStatus.PAID
      ? ticket.paidAmount
        ? ticket.paidAmount.toFixed(2)
        : ticket?.amount.toFixed(2)
      : ticket?.reminderDeadline
      ? (
          ticket?.amount +
          ticket?.reminderFee +
          ticket?.additionalCost -
          ticket?.paidAmount
        ).toFixed(2)
      : (ticket?.amount - ticket?.paidAmount).toFixed(2);

  const payableBefore = ticket?.reminderDeadline
    ? moment(ticket?.reminderDeadline).format("DD.MM.YYYY")
    : moment(paymentPeriodDate()).format("DD.MM.YYYY");

  useEffect(() => {
    if (openPayment) {
      startPayment();
    }
  }, [openPayment]);

  useEffect(() => {
    if (window.location.href.includes("paymentSuccessfull")) {
      setDatatransPaymentSuccessffullModalOpen(true);
    }
    if (window.location.href.includes("paymentError")) {
      setDatatransPaymentErrorModalOpen(true);
    }
  }, []);

  useEffect(() => {
      if(["en","de","fr","it"].includes(lang) && ticket?.documentLanguage && ticket.documentLanguage?.toLowerCase() !== lang){
        if(lang !== "en" || ticket.documentLanguage!=="de"){
          localStorage.setItem("beppoSelectedLanguage", String(ticket.documentLanguage));
          i18n.changeLanguage(String(ticket.documentLanguage)).then(() => {
            window.location.reload();
          })
        }
      }
    }, [ticket]);

  const changeRoute = (path: string) => {
    history.push(path);
  };

  const downloadPaymentSlip = async () => {
    dispatch(downloadSlip(ticket, lang, setOpenDownloadSlipInfoModal));
  };

  const startPayment = async () => {
    dispatch(
      payment(
        ticket,
        lang,
        setTransaction,
        setError,
        setOpenPayment,
        showLightbox
      )
    );
  };

  const backgroundBlue = {
    backgroundColor: "rgb(0, 90, 146)",
  };

  const renderContent = () => {
    if (isMobile && isGasStationTicket) {
      return <TicketDetailsGasStationMobile />;
    }
    if (isMobile) {
      return (
        <>
          <GenericMobileCard title={t("TicketDetails.Offense")}>
            {isLoading && <LoadingIndicator />}
            <div className={Style.formMobile__paragraph}>
              <span>{t("TicketDetails.Reference number").toString()}</span>
              <div className={Style.horizontalLine}></div>
              <span className={Style.formMobile__paragraphBold}>
                {ticket.number}
              </span>
            </div>
            <div className={Style.formMobile__paragraph}>
              <span>{t("TicketDetails.License plate").toString()}</span>
              <div className={Style.horizontalLine}></div>
              <span className={Style.formMobile__paragraphBold}>
                {ticket.licensePlate}
              </span>
            </div>
            <div className={Style.formMobile__paragraph}>
              <span>
                {t("TicketDetails.Place of violation Mobile").toString()}
              </span>
              <div className={Style.horizontalLine}></div>
              <span className={Style.formMobile__paragraphBold}>
                {ticket.address.toString().split(",")[1]}
              </span>
            </div>
            <div className={Style.formMobile__paragraph}>
              <span>{t<string>("TicketDetails.Violation date Mobile")}</span>
              <div className={Style.horizontalLine}></div>
              <span className={Style.formMobile__paragraphBold}>
                {moment(ticket.date).format("DD.MM.YYYY")}
              </span>
            </div>
            <div className={Style.formMobile__paragraph}>
              <span>{t("TicketDetails.Violation time Mobile").toString()}</span>
              <div className={Style.horizontalLine}></div>
              <span className={Style.formMobile__paragraphBold}>
                {moment(ticket.date).format("H:mm")}
              </span>
            </div>
            <div className={Style.formMobile__paragraph}>
              <span>{t("TicketDetails.Amount").toString()}</span>
              <div className={Style.horizontalLine}></div>
              <span className={Style.formMobile__paragraphBold}>
                {totalAmount}
              </span>
            </div>
            <div className={Style.formMobile__paragraph}>
              <span>
                {ticket.status === TicketStatus.PAID
                  ? t("TicketDetails.Payment date").toString()
                  : t("TicketDetails.Payable before").toString()}
              </span>
              <div className={Style.horizontalLine}></div>
              <span className={Style.formMobile__paragraphBold}>
                {ticket.status === TicketStatus.PAID
                  ? ticket?.paymentDate
                    ? moment(ticket?.paymentDate).format("DD.MM.YYYY")
                    : "n/a"
                  : payableBefore}
              </span>
            </div>
            {i18n.language === "de" ? (
              <div className={Style.formMobile__paragraphDesc}>
                {t<string>("TicketDetails.Text below.firstPart")}
                <span
                  onClick={() => changeRoute("/pz-text")}
                  className={Style.formMobile__mobileLinks}
                >
                  {t("TicketDetails.Text below.secondPart").toString()}
                </span>
                {t<string>("TicketDetails.Text below.thirdPart")}
                <span
                  onClick={() => changeRoute("/evidence")}
                  className={Style.formMobile__mobileLinks}
                >
                  {t("TicketDetails.Text below.fourthPart").toString()}
                </span>
                {t<string>("TicketDetails.Text below.fifthPart")}
                <p></p>
                {!FINAL_STATUSES.includes(ticket.status) ? (
                  <>{t<string>("TicketDetails.Text below.sixthPartMobile")}</>
                ) : (
                  <p>
                    <span>
                      {t(
                        "TicketDetails.Text after payment.firstPart"
                      ).toString()}
                    </span>
                    <span>
                      {t(
                        "TicketDetails.Text after payment.secondPart"
                      ).toString()}
                    </span>
                    <span>
                      {t(
                        `TicketDetails.Text after payment.thirdPart.${ticket.status}`
                      ).toString()}
                    </span>
                  </p>
                )}
                {ticket.objectionEnabled &&
                  ticket.status !== TicketStatus.PAID &&
                  !ticket.appeal &&
                  !appeal && (
                    <>
                      <span>
                        {t<string>("TicketDetails.Text below.seventhPart")}
                      </span>
                      <span
                        onClick={() => changeRoute("/complain")}
                        className={Style.formMobile__mobileLinks}
                      >
                        {t<string>("TicketDetails.Text below.eightPart")}
                      </span>
                    </>
                  )}
              </div>
            ) : (
              <div className={Style.formMobile__paragraphDesc}>
                {t<string>("TicketDetails.Text below.firstPart")}
                <span
                  onClick={() => changeRoute("/pz-text")}
                  className={Style.formMobile__mobileLinks}
                >
                  {t<string>("TicketDetails.Text below.secondPart")}
                </span>
                {t<string>("TicketDetails.Text below.thirdPart")}
                <span
                  onClick={() => changeRoute("/evidence")}
                  className={Style.formMobile__mobileLinks}
                >
                  {t<string>("TicketDetails.Text below.fourthPart")}
                </span>
                {t<string>("TicketDetails.Text below.fifthPart")}
                <p></p>
                {!FINAL_STATUSES.includes(ticket.status) ? (
                  <>{t("TicketDetails.Text below.sixthPartMobile")}</>
                ) : (
                  <p>
                    <span>
                      {t<string>("TicketDetails.Text after payment.firstPart")}
                    </span>
                    <span>
                      {t<string>("TicketDetails.Text after payment.secondPart")}
                    </span>
                    <span>
                      {t<string>(`TicketDetails.Text after payment.thirdPart.${ticket.status}`)}
                    </span>
                  </p>
                )}
                {ticket.objectionEnabled &&
                  ticket.status !== TicketStatus.PAID &&
                  !ticket.appeal &&
                  !appeal && (
                    <>
                      <span>
                        {t<string>("TicketDetails.Text below.seventhPart")}
                      </span>
                      <span
                        onClick={() => changeRoute("/complain")}
                        className={Style.formMobile__mobileLinks}
                      >
                        {t<string>("TicketDetails.Text below.eightPart")}
                      </span>
                    </>
                  )}
              </div>
            )}
            {!FINAL_STATUSES.includes(ticket.status) && (
              <>
                <div className={Style.onlinePaymentButtonPosition}>
                  <PaymentButton
                    style={backgroundBlue}
                    desktop={false}
                    text={t("TicketDetails.Online payment")}
                    imgSrc={CreditCardWhite}
                    imgAlt="cardWhite"
                    onClick={() => {
                      setOpenPayment(true);
                    }}
                  />
                  <div style={{ flex: 0.05 }}></div>
                  <div
                    className={Style.btnMobilePrimary}
                    onClick={() => changeRoute("/bank-transfer")}
                  >
                    <img src={MoneyIcon} alt="credit-card" />
                    <p className={Style.btnTextWhite}>
                      {t<string>("TicketDetails.BankTransfer")}
                    </p>
                  </div>
                </div>
                <div className={Style.whiteButtonsMobile}>
                  <div
                    className={Style.whiteButtonsMobile__buttonsRowContainer}
                  >
                    <div
                      className={Style.btnMobileWhite}
                      onClick={() => downloadPaymentSlip()}
                    >
                      <img src={DownloadBlue} alt="download" />
                      <p className={Style.btnTextBlue}>
                        {t<string>(
                          "TicketDetails.Download the payment slip mobile"
                        )}
                      </p>
                    </div>
                    <div style={{ flex: 0.05 }}></div>
                    <div
                      className={Style.btnMobileWhite}
                      onClick={() => changeRoute("/order")}
                    >
                      <img src={EnvelopeBlue} alt="shipping-fast" />
                      <p className={Style.btnTextBlue}>
                        {t<string>("TicketDetails.Order a payment slip")}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}
            {lightbox && transaction !== "" && (
              <BeppoLightBox
                transactionId={transaction}
                production={isProduction}
                onLoaded={() => onLoaded()}
                onOpened={() => onOpened()}
                onCancelled={() => onCancelled()}
                onError={() => onError()}
              />
            )}

            {datatransPaymentSuccessffullModalOpen && (
              <InfoModal
                open={datatransPaymentSuccessffullModalOpen}
                onClose={setTimeout(() => {
                  setDatatransPaymentSuccessffullModalOpen(false);
                  window.location.replace("/ticket-details");
                }, 4000)}
                title={t("InfoModals.Online payment successfull title")}
                infoText={t("InfoModals.Online payment successfull text")}
              />
            )}

            {datatransPaymentErrorModalOpen && (
              <InfoModal
                open={datatransPaymentErrorModalOpen}
                onClose={setTimeout(() => {
                  setDatatransPaymentErrorModalOpen(false);
                  window.location.replace("/ticket-details");
                }, 4000)}
                title={t("InfoModals.Online payment error title")}
                infoText={t("InfoModals.Online payment error text")}
              />
            )}
          </GenericMobileCard>
        </>
      );
    } else {
      if (isGasStationTicket) {
        return <TicketDetailsGasStationDesktop />;
      } else {
        return (
          <div className={Style.mainContainerDesktop}>
            <Navbar />
            {isLoading && <LoadingIndicator />}
            <div className={Style.formDesktop}>
              <h3 className={Style.formDesktop__subtitle}>
                {t<string>("TicketDetails.Offense")}
              </h3>
              <div className={Style.formDesktop__scrollableDiv}>
                <div className={Style.formDesktop__detailsAndImgContainer}>
                  <div className={Style.formDesktop__detailsContainer}>
                    <p className={Style.formDesktop__paragraph}>
                      {t<string>("TicketDetails.Prohibition offense")}
                    </p>
                    <div className={Style.detailsContainer}>
                      <div>
                        <span className={Style.detailsContainer__labels}>
                          {t<string>("TicketDetails.Reference number")}
                        </span>
                      </div>
                      <div className={Style.horizontalLine}></div>
                      <div className={Style.detailsContainer__valueContainer}>
                        <span className={Style.detailsContainer__boldValues}>
                          {ticket.number}
                        </span>
                      </div>
                    </div>
                    <div className={Style.detailsContainer}>
                      <div>
                        <span className={Style.detailsContainer__labels}>
                          {t<string>("TicketDetails.License plate")}
                        </span>
                      </div>
                      <div className={Style.horizontalLine}></div>
                      <div className={Style.detailsContainer__valueContainer}>
                        <span className={Style.detailsContainer__boldValues}>
                          {ticket.licensePlate}
                        </span>
                      </div>
                    </div>
                    <div className={Style.detailsContainer}>
                      <div>
                        <span className={Style.detailsContainer__labels}>
                          {t<string>("TicketDetails.Place of violation")}
                        </span>
                      </div>
                      <div className={Style.horizontalLine}></div>
                      <div className={Style.detailsContainer__valueContainer}>
                        <span className={Style.detailsContainer__boldValues}>
                          {ticket.address}
                        </span>
                      </div>
                    </div>
                    <div className={Style.detailsContainer}>
                      <div>
                        <span className={Style.detailsContainer__labels}>
                          {t<string>("TicketDetails.Violation date")}
                        </span>
                      </div>
                      <div className={Style.horizontalLine}></div>
                      <div className={Style.detailsContainer__valueContainer}>
                        <span className={Style.detailsContainer__boldValues}>
                          {moment(ticket.date).format("DD.MM.YYYY")}
                        </span>
                      </div>
                    </div>
                    <div className={Style.detailsContainer}>
                      <div>
                        <span className={Style.detailsContainer__labels}>
                          {t<string>("TicketDetails.Violation time")}
                        </span>
                      </div>
                      <div className={Style.horizontalLine}></div>
                      <div className={Style.detailsContainer__valueContainer}>
                        <span className={Style.detailsContainer__boldValues}>
                          {moment(ticket.date).format("HH:mm")}{" "}
                        </span>
                      </div>
                    </div>
                    <div className={Style.detailsContainer}>
                      <div>
                        <span className={Style.detailsContainer__labels}>
                          {t<string>("TicketDetails.Amount")}
                        </span>
                      </div>
                      <div className={Style.horizontalLine}></div>
                      <div className={Style.detailsContainer__valueContainer}>
                        <span className={Style.detailsContainer__boldValues}>
                          {totalAmount}
                        </span>
                      </div>
                    </div>
                    <div className={Style.detailsContainer}>
                      <div>
                        <span className={Style.detailsContainer__labels}>
                          <>
                            {ticket.status === TicketStatus.PAID
                              ? t("TicketDetails.Payment date")
                              : t("TicketDetails.Payable before")}
                          </>
                        </span>
                      </div>
                      <div className={Style.horizontalLine}></div>
                      <div className={Style.detailsContainer__valueContainer}>
                        <span className={Style.detailsContainer__boldValues}>
                          {ticket.status === TicketStatus.PAID
                            ? moment(ticket?.paymentDate).format("DD.MM.YYYY")
                            : payableBefore}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={Style.detailsAndImgContainer__imgContainer}>
                    <img src={images[0]} alt="" height={"100%"} />
                  </div>
                </div>
                <p></p>
                {i18n.language === "de" ? (
                  <div className={Style.formDesktop__paragraph}>
                    {t<string>("TicketDetails.Text below.firstPart")}
                    <span
                      onClick={() => {
                        setOpenPZTextModal(true);
                      }}
                      className={Style.formDesktop__links}
                    >
                      {t<string>("TicketDetails.Text below.secondPart")}
                    </span>
                    {t<string>("TicketDetails.Text below.thirdPart")}
                    <span
                      onClick={() => {
                        setOpenEvidencePhotosModal(true);
                      }}
                      className={Style.formDesktop__links}
                    >
                      {t<string>("TicketDetails.Text below.fourthPart")}
                    </span>
                    {t<string>("TicketDetails.Text below.fifthPart")}
                    <p></p>
                    <p>
                      {!FINAL_STATUSES.includes(ticket.status) ? (
                        <>{t("TicketDetails.Text below.sixthPart")}</>
                      ) : (
                        <p>
                          <span>
                            {t<string>(
                              "TicketDetails.Text after payment.firstPart"
                            )}
                          </span>
                          <span>
                            {t<string>(
                              "TicketDetails.Text after payment.secondPart"
                            )}
                          </span>
                          <span>
                            {t<string>(
                              `TicketDetails.Text after payment.thirdPart.${ticket.status}`
                            )}
                          </span>
                        </p>
                      )}
                      {ticket.objectionEnabled &&
                        ticket.status !== TicketStatus.PAID &&
                        !ticket.appeal &&
                        !appeal && (
                          <>
                            <span>
                              {t<string>(
                                "TicketDetails.Text below.seventhPart"
                              )}
                            </span>
                            <span
                              onClick={() => {
                                setOpenObjectionModal(true);
                              }}
                              className={Style.formDesktop__links}
                            >
                              {t<string>("TicketDetails.Text below.eightPart")}
                            </span>
                          </>
                        )}
                    </p>
                  </div>
                ) : (
                  <div className={Style.formDesktop__paragraph}>
                    {t<string>("TicketDetails.Text below.firstPart")}
                    <span
                      onClick={() => {
                        setOpenPZTextModal(true);
                      }}
                      className={Style.formDesktop__links}
                    >
                      {t<string>("TicketDetails.Text below.secondPart")}
                    </span>
                    {t<string>("TicketDetails.Text below.thirdPart")}
                    <span
                      onClick={() => {
                        setOpenEvidencePhotosModal(true);
                      }}
                      className={Style.formDesktop__links}
                    >
                      {t<string>("TicketDetails.Text below.fourthPart")}
                    </span>
                    {t<string>("TicketDetails.Text below.fifthPart")}
                    <p></p>
                    <p>
                      {!FINAL_STATUSES.includes(ticket.status) ? (
                        <>{t("TicketDetails.Text below.sixthPart")}</>
                      ) : (
                        <p>
                          <span>
                            {t<string>(
                              "TicketDetails.Text after payment.firstPart"
                            )}
                          </span>
                          <span>
                            {t<string>(
                              "TicketDetails.Text after payment.secondPart"
                            )}
                          </span>
                          <span>
                            {t<string>(
                              `TicketDetails.Text after payment.thirdPart.${ticket.status}`
                            )}
                          </span>
                        </p>
                      )}
                      {ticket.objectionEnabled &&
                        ticket.status !== TicketStatus.PAID &&
                        !ticket.appeal &&
                        !appeal && (
                          <>
                            <span>
                              {t<string>(
                                "TicketDetails.Text below.seventhPart"
                              )}
                            </span>
                            <span
                              onClick={() => {
                                setOpenObjectionModal(true);
                              }}
                              className={Style.formDesktop__links}
                            >
                              {t<string>("TicketDetails.Text below.eightPart")}
                            </span>
                          </>
                        )}
                    </p>
                  </div>
                )}
              </div>
              {!FINAL_STATUSES.includes(ticket.status) && (
                <>
                  <div>
                    <div className={Style.formDesktop__fourButtonsContainer}>
                      <div style={{ display: "flex", flex: 1 }}>
                        <div
                          className={Style.btnPrimary}
                          onClick={() => {
                            setOpenBankTransferModal(true);
                          }}
                        >
                          <img
                            src={MoneyIcon}
                            alt="cardWhite"
                            className={
                              Style.formDesktop__fourButtonsContainer__imgDesktop
                            }
                          />
                          <p
                            className={
                              Style.formDesktop__fourButtonsContainer__buttonTextWhite
                            }
                          >
                            {t<string>("TicketDetails.BankTransfer")}
                          </p>
                        </div>
                        <div style={{ flex: 0.1 }}></div>
                        <PaymentButton
                          style={backgroundBlue}
                          text={t("TicketDetails.Online payment")}
                          desktop
                          imgSrc={CreditCardWhite}
                          imgAlt="cardWhite"
                          onClick={() => {
                            setOpenPayment(true);
                          }}
                        />
                      </div>
                      <div
                        className={
                          Style.formDesktop__fourButtonsContainer__whiteBtnMargin
                        }
                      ></div>
                      <div style={{ display: "flex", flex: 1 }}>
                        <div
                          className={Style.btnWhite}
                          onClick={() => downloadPaymentSlip()}
                        >
                          <img
                            src={DownloadBlue}
                            alt="downloadBlue"
                            className={
                              Style.formDesktop__fourButtonsContainer__icon
                            }
                          />
                          <p className={Style.btnTextBlue}>
                            {t<string>(
                              "TicketDetails.Download the payment slip"
                            )}
                          </p>
                        </div>
                        <div style={{ flex: 0.1 }}></div>
                        <div
                          className={Style.btnWhite}
                          onClick={() => {
                            setOpenOrderBillModal(true);
                          }}
                        >
                          <img
                            src={ShippingFastBlue}
                            alt="fastBlue"
                            className={
                              Style.formDesktop__fourButtonsContainer__icon
                            }
                          />
                          <p className={Style.btnTextBlue}>
                            {t<string>("TicketDetails.Order a payment slip")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            {openPZTextModal && (
              <ParkingZoneTextModal
                open={openPZTextModal}
                value={ticket.parkingZone.parkingZoneText}
                onClose={() => setOpenPZTextModal(false)}
                title={t("TicketDetails.parkingZoneText")}
              />
            )}
            {openEvidencePhotosModal && (
              <EvidencePhotosModal
                open={openEvidencePhotosModal}
                onClose={() => setOpenEvidencePhotosModal(false)}
                title={t("TicketDetails.Photos")}
              />
            )}
            {openObjectionModal && (
              <ObjectionModal
                open={openObjectionModal}
                onClose={() => setOpenObjectionModal(false)}
                title={t("TicketDetails.Objection")}
              />
            )}
            {openOrderBillModal && (
              <OrderBillModal
                open={openOrderBillModal}
                onClose={() => setOpenOrderBillModal(false)}
                openInfoModal={() => setOpenOrderSlipInfoModal(true)}
                title={t("Order.Order a payment slip")}
              />
            )}
            {openOrderSlipInfoModal && (
              <InfoModal
                open={openOrderSlipInfoModal}
                onClose={setTimeout(() => {
                  setOpenOrderSlipInfoModal(false);
                }, 4000)}
                title={t("InfoModals.Invoice order title")}
                infoText={t("InfoModals.Invoice order text")}
              />
            )}
            {openBankTransferModal && (
              <BankTransferModal
                open={openBankTransferModal}
                onClose={() => setOpenBankTransferModal(false)}
                title={t("TicketDetails.BankTransfer")}
              />
            )}

            {lightbox && transaction !== "" && (
              <BeppoLightBox
                transactionId={transaction}
                production={isProduction}
                onLoaded={() => onLoaded()}
                onOpened={() => onOpened()}
                onCancelled={() => onCancelled()}
                onError={() => onError()}
              />
            )}

            {datatransPaymentSuccessffullModalOpen && (
              <InfoModal
                open={datatransPaymentSuccessffullModalOpen}
                onClose={setTimeout(() => {
                  setDatatransPaymentSuccessffullModalOpen(false);
                  window.location.replace("/ticket-details");
                }, 4000)}
                title={t("InfoModals.Online payment successfull title")}
                infoText={t("InfoModals.Online payment successfull text")}
              />
            )}

            {datatransPaymentErrorModalOpen && (
              <InfoModal
                open={datatransPaymentErrorModalOpen}
                onClose={setTimeout(() => {
                  setDatatransPaymentErrorModalOpen(false);
                  window.location.replace("/ticket-details");
                }, 4000)}
                title={t("InfoModals.Online payment error title")}
                infoText={t("InfoModals.Online payment error text")}
              />
            )}
          </div>
        );
      }
    }
  };

  return renderContent();
};

export default TiketDetails;
