import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import PaymentButton from "../common/button/paymentButton";
import CreditCardWhite from "../common/assets/credit-cardWhite.svg";
import MoneyIcon from "../common/assets/money-icon.svg";
import Style from "./ticketsDetails.module.scss";
import { useTranslation } from "react-i18next";
import InfoModal from "./infoModal";
import { RootState } from "../reducers";
import BeppoLightBox from "../reusable/BeppoLightBox";
import { downloadSlip, payment } from "../actions/ticket";
import { Ticket, TicketStatus } from "../reducers/ticket";
import GenericMobileCard from "./general/genericMobileCard";
import { useHistory } from "react-router-dom";
import DownloadBlue from "../common/assets/downloadBlue.svg";
import Hammer from "../common/assets/hammer.svg";
import EntityDetail from "./general/entityDetail";

const FINAL_STATUSES: string[] = [TicketStatus.PAID, TicketStatus.STOPPED];

const TicketDetailsGasStationMobile = () => {
  const ticket: Ticket = useSelector((state: RootState) => state.ticket.ticket);
  const appeal = useSelector((state: RootState) => state.ticket.appeal);
  const dispatch = useDispatch();
  const history = useHistory();
  const [, setError] = useState("");
  const { t, i18n } = useTranslation();
  const [, setOpenDownloadSlipInfoModal] = useState(false);
  const [openPayment, setOpenPayment] = useState(false);
  const [lightbox, showLightbox] = useState(false);
  const [transaction, setTransaction] = useState("");
  const [
    datatransPaymentSuccessffullModalOpen,
    setDatatransPaymentSuccessffullModalOpen,
  ] = useState(false);
  const [datatransPaymentErrorModalOpen, setDatatransPaymentErrorModalOpen] =
    useState(false);
  const selectedLanguage = localStorage.getItem("beppoSelectedLanguage");
  const lang = selectedLanguage ? selectedLanguage : i18n.language;

  const datatransProductionMode =
    process.env.REACT_APP_DATATRANS_PRODUCTION_MODE;

  var isProduction = datatransProductionMode === "true";

  const onLoaded = () => console.log("Loaded");
  const onOpened = () => console.log("Opened");
  const onCancelled = () => {
    console.log("Error:", "Cancel");
    showLightbox(false);
  };
  const onError = () => {
    console.log("Error with datatrans");
    showLightbox(false);
  };

  const paymentPeriodDate = () => {
      let date = new Date(ticket.date);
      if(ticket.status == "PENDING"){
          date = new Date();
      }
      if(ticket.issueDate!=null) {
          date = new Date(ticket.issueDate);
      }
      date.setDate(date.getDate() + ticket?.paymentPeriod);
      return date;
  };
  const changeRoute = (path: string) => {
    history.push(path);
  };

  const totalAmount =
    ticket.gasStationZone != null
      ? (ticket.totalGasStationAmount-ticket?.paidAmount).toFixed(2)
      : ticket.status === TicketStatus.PAID
      ? ticket.paidAmount
        ? ticket.paidAmount.toFixed(2)
        : ticket?.amount.toFixed(2)
      : ticket?.reminderDeadline
      ? (
          ticket?.amount +
          ticket?.reminderFee +
          ticket?.additionalCost -
          ticket?.paidAmount
        ).toFixed(2)
      : (ticket?.amount - ticket?.paidAmount).toFixed(2);

  const payableBefore = ticket?.reminderDeadline
    ? moment(ticket?.reminderDeadline).format("DD.MM.YYYY")
    : moment(paymentPeriodDate()).format("DD.MM.YYYY");

  useEffect(() => {
    if (openPayment) {
      startPayment();
    }
  }, [openPayment]);

  useEffect(() => {
    if (window.location.href.includes("paymentSuccessfull")) {
      setDatatransPaymentSuccessffullModalOpen(true);
    }
    if (window.location.href.includes("paymentError")) {
      setDatatransPaymentErrorModalOpen(true);
    }
  }, []);

  const downloadPaymentSlip = async () => {
    dispatch(downloadSlip(ticket, lang, setOpenDownloadSlipInfoModal));
  };

  const startPayment = async () => {
    dispatch(
      payment(
        ticket,
        lang,
        setTransaction,
        setError,
        setOpenPayment,
        showLightbox
      )
    );
  };

  const backgroundBlue = {
    backgroundColor: "rgb(0, 90, 146)",
  };

  return (
    <GenericMobileCard title={t("TicketDetails.OffenseGasStation")}>
      <EntityDetail
        value={ticket.number}
        title={t("TicketDetails.Reference number")}
      />
      <EntityDetail
        value={ticket.licensePlate}
        title={t("TicketDetails.License plate")}
      />
      <EntityDetail
        value={ticket.gasStationZone.name}
        title={t("TicketDetails.GasStationZoneName")}
      />
      <EntityDetail
        value={moment(ticket.date).format("DD.MM.YYYY")}
        title={t("TicketDetails.Violation date Mobile")}
      />
      <EntityDetail
        value={moment(ticket.date).format("H:mm")}
        title={t("TicketDetails.Violation time Mobile")}
      />
      <EntityDetail
        value={ticket.gasStationTicketDetails.pillar}
        title={t("TicketDetails.Pillar")}
      />
      <EntityDetail
        value={ticket.gasStationTicketDetails.fuel}
        title={t("TicketDetails.Fuel")}
      />
      <EntityDetail
        value={ticket.gasStationTicketDetails.fuelVolume}
        title={t("TicketDetails.FuelVolume")}
      />
      <EntityDetail value={totalAmount} title={t("TicketDetails.Amount")} />
      <EntityDetail
        value={
          ticket.status === TicketStatus.PAID
            ? moment(ticket?.paymentDate).format("DD.MM.YYYY")
            : payableBefore
        }
        title={
          ticket.status === TicketStatus.PAID
            ? t("TicketDetails.Payment date")
            : t("TicketDetails.Payable before")
        }
      />
      {!FINAL_STATUSES.includes(ticket.status) ? (
        <div className={Style.formMobile__paragraphDesc}>
          {t<string>("TicketDetails.Text below.firstPartGasStation")}

          <span
            onClick={() => changeRoute("/evidence")}
            className={Style.formMobile__mobileLinks}
          >
            {t<string>("TicketDetails.Text below.secondPartGasStation")}
          </span>
          {t<string>("TicketDetails.Text below.thirdPartGasStation")}

          {!FINAL_STATUSES.includes(ticket.status) && !ticket.appeal && !appeal && (
            <>
              <span>{t<string>("TicketDetails.Text below.seventhPart")}</span>
              <span
                onClick={() => changeRoute("/complain")}
                className={Style.formMobile__mobileLinks}
              >
                {t<string>("TicketDetails.Text below.eightPart")}
              </span>
            </>
          )}
        </div>
      ) : (
        <div className={Style.formMobile__paragraphDesc}>
          {t(`TicketDetails.Text below.fifthPartGasStation.${ticket.status}`).toString()}
        </div>
      )}

      {!FINAL_STATUSES.includes(ticket.status) && (
        <>
          <div className={Style.onlinePaymentButtonPosition}>
            <PaymentButton
              style={backgroundBlue}
              desktop={false}
              text={t("TicketDetails.Online payment")}
              imgSrc={CreditCardWhite}
              imgAlt="cardWhite"
              onClick={() => {
                setOpenPayment(true);
              }}
            />
            <div style={{ flex: 0.05 }}></div>
            <div
              className={Style.btnMobilePrimary}
              onClick={() => changeRoute("/bank-transfer")}
            >
              <img src={MoneyIcon} alt="credit-card" />
              <p className={Style.btnTextWhite}>
                {t<string>("TicketDetails.BankTransfer")}
              </p>
            </div>
          </div>
          <div className={Style.whiteButtonsMobile}>
            <div className={Style.whiteButtonsMobile__buttonsRowContainer}>
              <div
                className={Style.btnMobileWhite}
                onClick={() => downloadPaymentSlip()}
              >
                <img src={DownloadBlue} alt="download" />
                <p className={Style.btnTextBlue}>
                  {t<string>("TicketDetails.Download the payment slip mobile")}
                </p>
              </div>
              <div style={{ flex: 0.05 }}></div>
              <div
                className={Style.btnMobileWhite}
                onClick={() => changeRoute("/socarPhoto")}
              >
                <img src={Hammer} alt="shipping-fast" />
                <p className={Style.btnTextBlue}>
                  {t<string>("TicketDetails.socarTitle")}
                </p>
              </div>
            </div>
          </div>
        </>
      )}
      {lightbox && transaction !== "" && (
        <BeppoLightBox
          transactionId={transaction}
          production={isProduction}
          onLoaded={() => onLoaded()}
          onOpened={() => onOpened()}
          onCancelled={() => onCancelled()}
          onError={() => onError()}
        />
      )}

      {datatransPaymentSuccessffullModalOpen && (
        <InfoModal
          open={datatransPaymentSuccessffullModalOpen}
          onClose={setTimeout(() => {
            setDatatransPaymentSuccessffullModalOpen(false);
            window.location.replace("/ticket-details");
          }, 4000)}
          title={t("InfoModals.Online payment successfull gas station title")}
          infoText={t("InfoModals.Online payment successfull gas station text")}
        />
      )}

      {datatransPaymentErrorModalOpen && (
        <InfoModal
          open={datatransPaymentErrorModalOpen}
          onClose={setTimeout(() => {
            setDatatransPaymentErrorModalOpen(false);
            window.location.replace("/ticket-details");
          }, 4000)}
          title={t("InfoModals.Online payment error title")}
          infoText={t("InfoModals.Online payment error text")}
        />
      )}
    </GenericMobileCard>
  );
};

export default TicketDetailsGasStationMobile;
