import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../reusable/navbar";
import PaymentButton from "../common/button/paymentButton";
import CreditCardWhite from "../common/assets/credit-cardWhite.svg";
import Hammer from "../common/assets/hammer.svg";
import MoneyIcon from "../common/assets/money-icon.svg";
import Style from "./ticketsDetails.module.scss";
import { useTranslation } from "react-i18next";
import EvidencePhotosModal from "./evidencePhotosModal";
import ObjectionModal from "./objectionModal";
import InfoModal from "./infoModal";
import BankTransferModal from "./bankTransferModal";
import { RootState } from "../reducers";
import BeppoLightBox from "../reusable/BeppoLightBox";
import { downloadSlip, payment } from "../actions/ticket";
import { Ticket, TicketStatus } from "../reducers/ticket";
import SocarPhoto from "./socarPhotoModal";
import DownloadBlue from "../common/assets/downloadBlue.svg";

const FINAL_STATUSES: string[] = [TicketStatus.PAID, TicketStatus.STOPPED];

const TicketDetailsGasStationDesktop = () => {
  const ticket: Ticket = useSelector((state: RootState) => state.ticket.ticket);
  const appeal = useSelector((state: RootState) => state.ticket.appeal);
  const dispatch = useDispatch();
  const [, setError] = useState("");
  const [openSocarPhoto, setOpenSocarPhoto] = useState(false);
  const [, setOpenDownloadSlipInfoModal] = useState(false);
  const [images] = useState(ticket.imageUrls);
  const { t, i18n } = useTranslation();
  const [openEvidencePhotosModal, setOpenEvidencePhotosModal] = useState(false);
  const [openObjectionModal, setOpenObjectionModal] = useState(false);
  const [openOrderSlipInfoModal, setOpenOrderSlipInfoModal] = useState(false);
  const [openPayment, setOpenPayment] = useState(false);
  const [openBankTransferModal, setOpenBankTransferModal] = useState(false);
  const [lightbox, showLightbox] = useState(false);
  const [transaction, setTransaction] = useState("");
  const [
    datatransPaymentSuccessffullModalOpen,
    setDatatransPaymentSuccessffullModalOpen,
  ] = useState(false);
  const [datatransPaymentErrorModalOpen, setDatatransPaymentErrorModalOpen] =
    useState(false);
  const selectedLanguage = localStorage.getItem("beppoSelectedLanguage");
  const lang = selectedLanguage ? selectedLanguage : i18n.language;

  const datatransProductionMode =
    process.env.REACT_APP_DATATRANS_PRODUCTION_MODE;

  var isProduction = datatransProductionMode === "true";

  const onLoaded = () => console.log("Loaded");
  const onOpened = () => console.log("Opened");
  const onCancelled = () => {
    console.log("Error:", "Cancel");
    showLightbox(false);
  };
  const onError = () => {
    console.log("Error with datatrans");
    showLightbox(false);
  };

  const paymentPeriodDate = () => {
    let date = new Date(ticket.date);
    if(ticket.status == "PENDING"){
      date = new Date();
    }
    if(ticket.issueDate!=null) {
      date = new Date(ticket.issueDate);
    }
    date.setDate(date.getDate() + ticket?.paymentPeriod);
    return date;
  };

  const totalAmount =
    ticket.gasStationZone != null
        ? (ticket.totalGasStationAmount-ticket?.paidAmount).toFixed(2)
      : ticket.status === TicketStatus.PAID
      ? ticket.paidAmount
        ? ticket.paidAmount.toFixed(2)
        : ticket?.amount.toFixed(2)
      : ticket?.reminderDeadline
      ? (
          ticket?.amount +
          ticket?.reminderFee +
          ticket?.additionalCost -
          ticket?.paidAmount
        ).toFixed(2)
      : (ticket?.amount - ticket?.paidAmount).toFixed(2);

  const payableBefore = ticket?.reminderDeadline
    ? moment(ticket?.reminderDeadline).format("DD.MM.YYYY")
    : moment(paymentPeriodDate()).format("DD.MM.YYYY");

  useEffect(() => {
    if (openPayment) {
      startPayment();
    }
  }, [openPayment]);

  useEffect(() => {
    if (window.location.href.includes("paymentSuccessfull")) {
      setDatatransPaymentSuccessffullModalOpen(true);
    }
    if (window.location.href.includes("paymentError")) {
      setDatatransPaymentErrorModalOpen(true);
    }
  }, []);

  const downloadPaymentSlip = async () => {
    dispatch(downloadSlip(ticket, lang, setOpenDownloadSlipInfoModal));
  };

  const startPayment = async () => {
    dispatch(
      payment(
        ticket,
        lang,
        setTransaction,
        setError,
        setOpenPayment,
        showLightbox
      )
    );
  };

  const backgroundBlue = {
    backgroundColor: "rgb(0, 90, 146)",
  };

  const renderContent = () => {
    return (
      <div className={Style.mainContainerDesktop}>
        <Navbar />
        <div className={Style.formDesktop}>
          <h3 className={Style.formDesktop__subtitle}>
            {t<string>("TicketDetails.OffenseGasStation")}
          </h3>
          <div className={Style.formDesktop__scrollableDiv}>
            <div className={Style.formDesktop__detailsAndImgContainer}>
              <div className={Style.formDesktop__detailsContainer}>
                <div className={Style.detailsContainer}>
                  <div>
                    <span className={Style.detailsContainer__labels}>
                      {t<string>("TicketDetails.Reference number")}
                    </span>
                  </div>
                  <div className={Style.horizontalLine}></div>
                  <div className={Style.detailsContainer__valueContainer}>
                    <span className={Style.detailsContainer__boldValues}>
                      {ticket.number}
                    </span>
                  </div>
                </div>
                <div className={Style.detailsContainer}>
                  <div>
                    <span className={Style.detailsContainer__labels}>
                      {t<string>("TicketDetails.License plate")}
                    </span>
                  </div>
                  <div className={Style.horizontalLine}></div>
                  <div className={Style.detailsContainer__valueContainer}>
                    <span className={Style.detailsContainer__boldValues}>
                      {ticket.licensePlate}
                    </span>
                  </div>
                </div>
                <div className={Style.detailsContainer}>
                  <div>
                    <span className={Style.detailsContainer__labels}>
                      {t<string>("TicketDetails.GasStationZoneName")}
                    </span>
                  </div>
                  <div className={Style.horizontalLine}></div>
                  <div className={Style.detailsContainer__valueContainer}>
                    <span className={Style.detailsContainer__boldValues}>
                      {ticket.gasStationZone.name}
                    </span>
                  </div>
                </div>
                <div className={Style.detailsContainer}>
                  <div>
                    <span className={Style.detailsContainer__labels}>
                      {t<string>("TicketDetails.Violation date")}
                    </span>
                  </div>
                  <div className={Style.horizontalLine}></div>
                  <div className={Style.detailsContainer__valueContainer}>
                    <span className={Style.detailsContainer__boldValues}>
                      {moment(ticket.date).format("DD.MM.YYYY")}
                    </span>
                  </div>
                </div>
                <div className={Style.detailsContainer}>
                  <div>
                    <span className={Style.detailsContainer__labels}>
                      {t<string>("TicketDetails.Violation time")}
                    </span>
                  </div>
                  <div className={Style.horizontalLine}></div>
                  <div className={Style.detailsContainer__valueContainer}>
                    <span className={Style.detailsContainer__boldValues}>
                      {moment(ticket.date).format("HH:mm")}{" "}
                    </span>
                  </div>
                </div>
                <div className={Style.detailsContainer}>
                  <div>
                    <span className={Style.detailsContainer__labels}>
                      {t<string>("TicketDetails.Fuel")}
                    </span>
                  </div>
                  <div className={Style.horizontalLine}></div>
                  <div className={Style.detailsContainer__valueContainer}>
                    <span className={Style.detailsContainer__boldValues}>
                      {ticket.gasStationTicketDetails?.fuel}
                    </span>
                  </div>
                </div>
                <div className={Style.detailsContainer}>
                  <div>
                    <span className={Style.detailsContainer__labels}>
                      {t<string>("TicketDetails.Pillar")}
                    </span>
                  </div>
                  <div className={Style.horizontalLine}></div>
                  <div className={Style.detailsContainer__valueContainer}>
                    <span className={Style.detailsContainer__boldValues}>
                      {ticket.gasStationTicketDetails?.pillar}
                    </span>
                  </div>
                </div>
                <div className={Style.detailsContainer}>
                  <div>
                    <span className={Style.detailsContainer__labels}>
                      {t<string>("TicketDetails.FuelVolume")}
                    </span>
                  </div>
                  <div className={Style.horizontalLine}></div>
                  <div className={Style.detailsContainer__valueContainer}>
                    <span className={Style.detailsContainer__boldValues}>
                      {ticket.gasStationTicketDetails?.fuelVolume}
                    </span>
                  </div>
                </div>
                <div className={Style.detailsContainer}>
                  <div>
                    <span className={Style.detailsContainer__labels}>
                      {t<string>("TicketDetails.Amount")}
                    </span>
                  </div>
                  <div className={Style.horizontalLine}></div>
                  <div className={Style.detailsContainer__valueContainer}>
                    <span className={Style.detailsContainer__boldValues}>
                      {totalAmount}
                    </span>
                  </div>
                </div>
                <div className={Style.detailsContainer}>
                  <div>
                    <span className={Style.detailsContainer__labels}>
                      <>
                        {ticket.status === TicketStatus.PAID
                          ? t("TicketDetails.Payment date")
                          : t("TicketDetails.Payable before")}
                      </>
                    </span>
                  </div>
                  <div className={Style.horizontalLine}></div>
                  <div className={Style.detailsContainer__valueContainer}>
                    <span className={Style.detailsContainer__boldValues}>
                      {ticket.status === TicketStatus.PAID
                        ? moment(ticket?.paymentDate).format("DD.MM.YYYY")
                        : payableBefore}
                    </span>
                  </div>
                </div>
              </div>
              <div className={Style.detailsAndImgContainer__imgContainer}>
                <img src={images[0]} alt="" height={"100%"} />
              </div>
            </div>
            <p></p>

            {!FINAL_STATUSES.includes(ticket.status) ? (
              <div className={Style.formDesktop__paragraph}>
                {t<string>("TicketDetails.Text below.firstPartGasStation")}
                <span
                  onClick={() => {
                    setOpenEvidencePhotosModal(true);
                  }}
                  className={Style.formDesktop__links}
                >
                  {t<string>("TicketDetails.Text below.secondPartGasStation")}
                </span>
                {t<string>("TicketDetails.Text below.thirdPartGasStation")}
                {!FINAL_STATUSES.includes(ticket.status) &&
                  !ticket.appeal &&
                  !appeal && (
                    <>
                      <span>
                        {t<string>("TicketDetails.Text below.seventhPart")}
                      </span>
                      <span
                        onClick={() => {
                          setOpenObjectionModal(true);
                        }}
                        className={Style.formDesktop__links}
                      >
                        {t<string>(
                          "TicketDetails.Text below.fourthPartGasStation"
                        )}
                      </span>
                    </>
                  )}
              </div>
            ) : (
              <div className={Style.formDesktop__paragraph}>
                {t(`TicketDetails.Text below.fifthPartGasStation.${ticket.status}`).toString()}
              </div>
            )}
          </div>
          {!FINAL_STATUSES.includes(ticket.status) && (
            <>
              <div>
                <div
                  className={Style.formDesktop__threeButtonsContainerGasStation}
                >
                  <div style={{ display: "flex", flex: 1 }}>
                    <div
                      className={Style.btnPrimary}
                      onClick={() => {
                        setOpenBankTransferModal(true);
                      }}
                    >
                      <img
                        src={MoneyIcon}
                        alt="cardWhite"
                        className={
                          Style.formDesktop__fourButtonsContainer__imgDesktop
                        }
                      />
                      <p
                        className={
                          Style.formDesktop__fourButtonsContainer__buttonTextWhite
                        }
                      >
                        {t<string>("TicketDetails.BankTransfer")}
                      </p>
                    </div>
                    <div style={{ flex: 0.1 }}></div>
                    <PaymentButton
                      style={backgroundBlue}
                      text={t("TicketDetails.Online payment")}
                      desktop
                      imgSrc={CreditCardWhite}
                      imgAlt="cardWhite"
                      onClick={() => {
                        setOpenPayment(true);
                      }}
                    />
                  </div>
                  <div
                    className={
                      Style.formDesktop__fourButtonsContainer__whiteBtnMargin
                    }
                  ></div>

                  <div style={{ display: "flex", flex: 1 }}>
                    <div
                      className={Style.btnWhite}
                      onClick={() => downloadPaymentSlip()}
                    >
                      <img
                        src={DownloadBlue}
                        alt="downloadBlue"
                        className={
                          Style.formDesktop__fourButtonsContainer__icon
                        }
                      />
                      <p className={Style.btnTextBlue}>
                        {t<string>("TicketDetails.Download the payment slip")}
                      </p>
                    </div>
                    <div style={{ flex: 0.1 }}></div>
                    <div
                      className={Style.btnWhite}
                      onClick={() => {
                        setOpenSocarPhoto(true);
                      }}
                    >
                      <img
                        src={Hammer}
                        alt="fastBlue"
                        className={
                          Style.formDesktop__fourButtonsContainer__icon
                        }
                      />
                      <p className={Style.btnTextBlue}>
                        {t<string>("TicketDetails.socarTitle")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        {openSocarPhoto && (
          <SocarPhoto
            open={openSocarPhoto}
            title={t("TicketDetails.socarTitle")}
            onClose={() => setOpenSocarPhoto(false)}
          />
        )}
        {openEvidencePhotosModal && (
          <EvidencePhotosModal
            open={openEvidencePhotosModal}
            onClose={() => setOpenEvidencePhotosModal(false)}
            title={t("TicketDetails.Photos")}
          />
        )}
        {openObjectionModal && (
          <ObjectionModal
            open={openObjectionModal}
            onClose={() => setOpenObjectionModal(false)}
            title={t("TicketDetails.Objection")}
            gasStationZone={ticket.gasStationZone}
          />
        )}

        {openOrderSlipInfoModal && (
          <InfoModal
            open={openOrderSlipInfoModal}
            onClose={setTimeout(() => {
              setOpenOrderSlipInfoModal(false);
            }, 4000)}
            title={t("InfoModals.Invoice order title")}
            infoText={t("InfoModals.Invoice order text")}
          />
        )}
        {openBankTransferModal && (
          <BankTransferModal
            open={openBankTransferModal}
            onClose={() => setOpenBankTransferModal(false)}
            title={t("TicketDetails.BankTransfer")}
          />
        )}

        {lightbox && transaction !== "" && (
          <BeppoLightBox
            transactionId={transaction}
            production={isProduction}
            onLoaded={() => onLoaded()}
            onOpened={() => onOpened()}
            onCancelled={() => onCancelled()}
            onError={() => onError()}
          />
        )}

        {datatransPaymentSuccessffullModalOpen && (
          <InfoModal
            open={datatransPaymentSuccessffullModalOpen}
            onClose={setTimeout(() => {
              setDatatransPaymentSuccessffullModalOpen(false);
              window.location.replace("/ticket-details");
            }, 4000)}
            title={t("InfoModals.Online payment successfull gas station title")}
            infoText={t(
              "InfoModals.Online payment successfull gas station text"
            )}
          />
        )}

        {datatransPaymentErrorModalOpen && (
          <InfoModal
            open={datatransPaymentErrorModalOpen}
            onClose={setTimeout(() => {
              setDatatransPaymentErrorModalOpen(false);
              window.location.replace("/ticket-details");
            }, 4000)}
            title={t("InfoModals.Online payment error title")}
            infoText={t("InfoModals.Online payment error text")}
          />
        )}
      </div>
    );
  };

  return renderContent();
};

export default TicketDetailsGasStationDesktop;
