import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import "react-responsive-modal/styles.css";
import Style from "./objectionModal.module.scss";
import { useDispatch, useSelector } from "react-redux";
import "react-image-gallery/styles/css/image-gallery.css";
import Button from "../common/button/button";
import LoadingIndicator from "../reusable/loadingIndicator";
import { RootState } from "../reducers";
import { GasStationZones, Objection } from "../reducers/ticket";
import LabelWithHR from "../common/label/labelWithHR";
import { createObjection } from "../actions/ticket";
import { useHistory } from "react-router-dom";
import GenericBeppoModal from "./general/genericBeppoModal";

interface ObjectionProps {
  onClose: () => void;
  open: boolean;
  title: string;
  gasStationZone?: GasStationZones;
}

const ObjectionModal = (props: ObjectionProps) => {
  const ticket = useSelector((state: RootState) => state.ticket.ticket);
  const { isLoading } = useSelector((state: RootState) => state.loader);

  const { t } = useTranslation();
  const { handleSubmit, control } = useForm();
  const [, setError] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();

  const [objection, setObjection] = useState<Objection>({
    firstName: "",
    lastName: "",
    email: "",
    complaintText: "",
    parkingZoneId: ticket.parkingZone?.id,
    gasStationZoneId: ticket.gasStationZone?.id,
    ticketId: ticket.id,
  });
  const sendObjection = async () => {
    dispatch(createObjection(objection, setError, history, props.onClose));
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <GenericBeppoModal
      title={props.title}
      open={props.open}
      onClose={props.onClose}
    >
      <form onSubmit={handleSubmit(sendObjection)}>
        <div className={Style.formContainer__row}>
          <div className={Style.formContainer__flexOne}>
            <LabelWithHR label={t("Objection.E-mail")} />
            <Controller
              name="email"
              defaultValue=""
              control={control}
              render={() => (
                <input
                  type={"text"}
                  className={Style.input}
                  name="email"
                  onChange={(e) => {
                    setObjection({ ...objection, email: e.target.value });
                  }}
                />
              )}
            />
          </div>
        </div>
        <div className={Style.formContainer__row}>
          <div className={Style.formContainer__flexOne__margin}>
            <LabelWithHR label={t("Objection.First name")} />
            <Controller
              name="firstName"
              defaultValue=""
              control={control}
              render={() => (
                <input
                  type={"text"}
                  className={Style.input}
                  name="firstName"
                  onChange={(e) => {
                    setObjection({
                      ...objection,
                      firstName: e.target.value,
                    });
                  }}
                />
              )}
            />
          </div>
          <div className={Style.formContainer__flexOne}>
            <LabelWithHR label={t("Objection.Last name")} />
            <Controller
              name="lastName"
              defaultValue=""
              control={control}
              render={() => (
                <input
                  type={"text"}
                  className={Style.input}
                  name="lastName"
                  onChange={(e) => {
                    setObjection({
                      ...objection,
                      lastName: e.target.value,
                    });
                  }}
                />
              )}
            />
          </div>
        </div>
        <div className={Style.formContainer__row}>
          <div className={Style.formContainer__flexOne}>
            <LabelWithHR label={t("Objection.Message")} />
            <Controller
              name="complaintText"
              defaultValue=""
              control={control}
              render={() => (
                <textarea
                  className={Style.textarea}
                  name="complaintText"
                  onChange={(e) => {
                    setObjection({
                      ...objection,
                      complaintText: e.target.value,
                    });
                  }}
                />
              )}
            />
          </div>
        </div>
        <Button
          classNameWrapper={Style.buttonDesktop}
          disabled={
            !objection.firstName ||
            !objection.lastName ||
            !objection.email ||
            !objection.complaintText
          }
          text={t("Objection.Send message")}
        />
      </form>
    </GenericBeppoModal>
  );
};

export default ObjectionModal;
