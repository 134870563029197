import React from "react";
import Lightbox from "react-datatrans-light-box";

const BeppoLightBox = (props) => {
  return (
    <Lightbox
      transactionId={props.transactionId}
      production={props.production}
      onLoaded={() => props.onLoaded()}
      onOpened={() => props.onOpened()}
      onCancelled={() => props.onCancelled()}
      onError={() => props.onError()}
    />
  );
};

export default BeppoLightBox;
